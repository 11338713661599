import DoubleCurrencyLogo from "components/DoubleLogo";
import TokenLogo3 from "../../assets/images/token3.svg";
import TokenLogo4 from "../../assets/images/token4.svg";
import { unwrappedToken } from "utils/unwrappedToken";
import { Dots } from "pages/Pool/styleds";
import { useTotalSupply } from "hooks/useTotalSupply";
import JSBI from "jsbi";
import { useTokenBalance } from "state/wallet/hooks";
import { useActiveWeb3React } from "hooks/web3";
import { Percent } from "@uniswap/sdk-core";
import { useState } from "react";
import { ButtonPrimary } from "components/Button";
import { Trans, t } from "@lingui/macro";
import { Link } from "react-router-dom";
import { RowBetween } from "components/Row";
import { BIG_INT_ZERO } from "../../constants/misc";
import { currencyId } from "../../utils/currencyId";

export default function LiquidityPool({ pair, index, stakedBalance }) {
  const { account } = useActiveWeb3React();
  const currency0 = unwrappedToken(pair.baseTokenAddress);
  const currency1 = unwrappedToken(pair.quoteTokenAddress);
  const totalPoolTokens = useTotalSupply(pair.liquidityToken);
  const userDefaultPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken);

  const userPoolBalance = stakedBalance
    ? userDefaultPoolBalance?.add(stakedBalance)
    : userDefaultPoolBalance;

  const poolTokenPercentage =
    !!userPoolBalance &&
    !!totalPoolTokens &&
    JSBI.greaterThanOrEqual(totalPoolTokens.quotient, userPoolBalance.quotient)
      ? new Percent(userPoolBalance.quotient, totalPoolTokens.quotient)
      : undefined;

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    //     // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.quotient, userPoolBalance.quotient)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false),
        ]
      : [undefined, undefined];

  const [show, setShow] = useState(false);

  const handleAction = () => {
    setShow((prevVal) => !prevVal);
  };

  return (
    <>
      <tr className={index % 2 !== 0 ? "even" : "odd"}>
        <td className="tokenlogo">
          <div className="tokenlogo-main">
            <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={45} />
          </div>
        </td>
        <td>
          <div className="token-detail name">
            <p>
              <p>{pair?.pairSymbol}</p>
              {/* {!currency0 || !currency1 ? (
                <Dots>
                  <p>Loading</p>
                </Dots>
              ) : (
                `${currency0.symbol}/${currency1.symbol}`
              )} */}
            </p>
            {/* <span>0.00%</span> */}
          </div>
        </td>
        <td>
          <div className="token-detail value">
            <p>{userPoolBalance ? userPoolBalance.toSignificant(4) : "-"}</p>
            {/* <span>0.00%</span> */}
          </div>
        </td>
        <td>
          <div className="token-detail value up">
            {/* <p>{token0Deposited?.toSignificant(6)}</p> */}
            {/* <span>22069.67%</span> */}
          </div>
        </td>
        <td>
          <div className="token-detail value down">
            {/* <p>{token1Deposited?.toSignificant(6)}</p> */}
            {/* <span>-26.20%</span> */}
          </div>
        </td>
        <td>
          <div className="token-detail value">
            {/* {poolTokenPercentage ? (
              <p>
                {poolTokenPercentage.toFixed(2) === "0.00"
                  ? "<0.01"
                  : poolTokenPercentage.toFixed(2)}{" "}
                %
              </p>
            ) : (
              "-"
            )} */}
          </div>
        </td>
        <td className="text-center">
          <div className="token-detail value" onClick={handleAction}>
            <p>...</p>
            {
              show && currency0 && currency1 && (
                //   userDefaultPoolBalance &&
                //   JSBI.greaterThan(userDefaultPoolBalance.quotient, BIG_INT_ZERO) && (
                <div className="menu-btn">
                  <ButtonPrimary
                    padding="8px"
                    borderRadius="8px 8px 0px 0px"                
                    as={Link}
                    to={`/add/v2/${currency0}/${currency1}`}
                    width="100%"
                  >
                    <span>{t`Add`}</span>
                  </ButtonPrimary>
                  <ButtonPrimary
                    padding="8px"
                    borderRadius="0px 0px 8px 8px"
                    as={Link}
                    width="100%"
                    to={`/remove/v2/${currency0}/${currency1}`}
                  >
                    <span>{t`Remove`}</span>
                  </ButtonPrimary>
                </div>
              )
              //   )
            }
          </div>
        </td>
      </tr>
    </>
  );
}
