import React from 'react'
import styled from 'styled-components/macro'

export const BodyWrapper = styled.div<{ margin?: string }>`
  position: relative;
  // margin-top: ${({ margin }) => margin ?? '0px'};
  // max-width: 600px;
  // min-height: 386px;
  width: 100%;
  // background: ${({ theme }) => theme.bg7};
  // box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
  //   0px 24px 32px rgba(0, 0, 0, 0.01);
  // border-radius: 24px;
  // margin-top: 1rem;
  // padding: 0.5rem;
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children, ...rest }: { children: React.ReactNode }) {
  return <BodyWrapper {...rest}>{children}</BodyWrapper>
}
