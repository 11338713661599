import { Contract } from '@ethersproject/contracts'
import { TransactionResponse } from '@ethersproject/providers'
import { Currency, Percent,TradeType } from '@uniswap/sdk-core'
import React, { useCallback, useContext, useMemo, useState ,useEffect} from 'react'
import { ArrowDown, Plus } from 'react-feather'
import ReactGA from 'react-ga'
import { RouteComponentProps } from 'react-router'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { ButtonPrimary, ButtonLight, ButtonError, ButtonConfirmed } from '../../components/Button'
import { BlueCard, LightCard } from '../../components/Card'
import { AutoColumn, ColumnCenter } from '../../components/Column'
import TransactionConfirmationModal, { ConfirmationModalContent } from '../../components/TransactionConfirmationModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { AddRemoveTabs } from '../../components/NavigationTabs'
import { MinimalPositionCard } from '../../components/PositionCard'
import Row, { RowBetween, RowFixed } from '../../components/Row'
import { Trade as V2Trade } from '@uniswap/v2-sdk'
import { Trade as V3Trade } from '@uniswap/v3-sdk'
import { ethers } from 'ethers';
import Slider from '../../components/Slider'
import CurrencyLogo from '../../components/CurrencyLogo'
import { WETH9_EXTENDED } from '../../constants/tokens'

import { useActiveWeb3React } from '../../hooks/web3'
import { useCurrency } from '../../hooks/Tokens'
import { usePairContract, useV2RouterContract } from '../../hooks/useContract'
import { useV2LiquidityTokenPermit } from '../../hooks/useERC20Permit'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'

import { useTransactionAdder } from '../../state/transactions/hooks'
import { StyledInternalLink, TYPE } from '../../theme'
import { calculateGasMargin } from '../../utils/calculateGasMargin'
import { calculateSlippageAmount } from '../../utils/calculateSlippageAmount'
import { currencyId } from '../../utils/currencyId'
import useDebouncedChangeHandler from '../../hooks/useDebouncedChangeHandler'
import AppBody from '../AppBody'
import { ClickableText, MaxButton, Wrapper } from '../Pool/styleds'
import { useApproveCallback, ApprovalState } from '../../hooks/useApproveCallback'
import { Dots } from '../../components/swap/styleds'
import { useBurnActionHandlers } from '../../state/burn/hooks'
import { useDerivedBurnInfo, useBurnState } from '../../state/burn/hooks'
import { Field } from '../../state/burn/actions'
import { useWalletModalToggle } from '../../state/application/hooks'
import { useUserSlippageToleranceWithDefault } from '../../state/user/hooks'
import { BigNumber } from '@ethersproject/bignumber'
import { t, Trans } from '@lingui/macro'
import RouterABI from '../Swap/RouterABI.json'
import ConfirmSwapModal from 'components/swap/ConfirmSwapModal'
import { useSwapState } from 'state/swap/hooks'
import { color } from 'styled-system'

declare var window:any
const DEFAULT_REMOVE_LIQUIDITY_SLIPPAGE_TOLERANCE = new Percent(5, 100)

export default function RemoveLiquidity({
  history,
  match: {
    params: { currencyIdA, currencyIdB },
  },
}: RouteComponentProps<{ currencyIdA: string; currencyIdB: string }>) {

  const [currencyA, currencyB] = [useCurrency(currencyIdA) ?? undefined, useCurrency(currencyIdB) ?? undefined]

  const { account, chainId, library } = useActiveWeb3React()
  const [tokenA, tokenB] = useMemo(() => [currencyA?.wrapped, currencyB?.wrapped], [currencyA, currencyB])

  const theme = useContext(ThemeContext)
  const [{ _showConfirm, tradeToConfirm, swapErrorMessage, _attemptingTxn, _txHash }, setSwapState] = useState<{
    _showConfirm: boolean
    tradeToConfirm: V2Trade<Currency, Currency, TradeType> | V3Trade<Currency, Currency, TradeType> | undefined
    _attemptingTxn: boolean
    swapErrorMessage: string | undefined
    _txHash: string | undefined
  }>({
    _showConfirm: false,
    tradeToConfirm: undefined,
    _attemptingTxn: false,
    swapErrorMessage: undefined,
    _txHash: undefined,
  }) 
 
  const [contractData, setContractData] = useState(null);
  const [isRoleGranted, setIsRoleGranted] = useState(false);
  const contractAddress = '0xCb227D0A84bCB40942c7fe2d8750ceab1F750B77';
const authRole:any = '0x992f44c638e29d2ba83ec39e831315f29d6b7188a87e5bf8311ef8074cd46e2d'

// Create a provider and contract instance outside the component
   useEffect(() => {
    const fetchData = async () => {

      if (currencyIdA === contractAddress || currencyIdB === contractAddress) {

        try { 
          let provider:any
          let contract:any
          // Create a provider and contract instance outside the component
          if (typeof window.ethereum !== "undefined") {
            provider = new ethers.providers.Web3Provider(window.ethereum);
            contract = new ethers.Contract(
              contractAddress,
              RouterABI,
              provider.getSigner()
            );
        
            // Now you can use 'provider' to interact with Ethereum
          } else {
            console.log("MetaMask is not installed");
          }
    
          const accounts = await provider.send("eth_requestAccounts", []);
          const data = await contract.hasRole(
            authRole,
            accounts[0],
            { gasLimit: 5000000000 }
          );
          setIsRoleGranted(data);
          if (data) {
            return;
          } 
          setSwapState({ _attemptingTxn: false, tradeToConfirm, _showConfirm: true, swapErrorMessage: 'You are not allowed to perform this action on this token ( Contact Token Admin )', _txHash: undefined });
          
          setContractData(data.toString());
        } catch (error) {
          console.error('Error handling input selection:', error);
        }
      }
    };
    if (typeof window.ethereum !== "undefined") {
    window.ethereum.on('accountsChanged', async function (accounts) {
      fetchData()
      })
    } 
    fetchData(); // Call fetchData when the component initializes
  }, []); // Trigger the effect only once on mount



  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // burn state
  const { independentField, typedValue } = useBurnState()
  const { pair, parsedAmounts, error } = useDerivedBurnInfo(currencyA ?? undefined, currencyB ?? undefined)
  const { onUserInput: _onUserInput } = useBurnActionHandlers()
  const isValid = !error

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showDetailed, setShowDetailed] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false) // clicked confirm

  // txn values
  const [txHash, setTxHash] = useState<string>('')
  const deadline = useTransactionDeadline()
  const allowedSlippage = useUserSlippageToleranceWithDefault(DEFAULT_REMOVE_LIQUIDITY_SLIPPAGE_TOLERANCE)

  const formattedAmounts = {
    [Field.LIQUIDITY_PERCENT]: parsedAmounts[Field.LIQUIDITY_PERCENT].equalTo('0')
      ? '0'
      : parsedAmounts[Field.LIQUIDITY_PERCENT].lessThan(new Percent('1', '100'))
      ? '<1'
      : parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0),
    [Field.LIQUIDITY]:
      independentField === Field.LIQUIDITY ? typedValue : parsedAmounts[Field.LIQUIDITY]?.toSignificant(6) ?? '',
    [Field.CURRENCY_A]:
      independentField === Field.CURRENCY_A ? typedValue : parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) ?? '',
    [Field.CURRENCY_B]:
      independentField === Field.CURRENCY_B ? typedValue : parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) ?? '',
  }

  const atMaxAmount = parsedAmounts[Field.LIQUIDITY_PERCENT]?.equalTo(new Percent('1'))

  // pair contract
  const pairContract: Contract | null = usePairContract(pair?.liquidityToken?.address)

  const router = useV2RouterContract()

  // allowance handling
  const { gatherPermitSignature, signatureData } = useV2LiquidityTokenPermit(
    parsedAmounts[Field.LIQUIDITY],
    router?.address
  )
  const [approval, approveCallback] = useApproveCallback(parsedAmounts[Field.LIQUIDITY], router?.address)

  async function onAttemptToApprove() {
    if (!pairContract || !pair || !library || !deadline) throw new Error('missing dependencies')
    const liquidityAmount = parsedAmounts[Field.LIQUIDITY]
    if (!liquidityAmount) throw new Error('missing liquidity amount')

    if (gatherPermitSignature) {
      try {
        await gatherPermitSignature()
      } catch (error) {
        // try to approve if gatherPermitSignature failed for any reason other than the user rejecting it
        if (error?.code !== 4001) {
          await approveCallback()
        }
      }
    } else {
      await approveCallback()
    }
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      return _onUserInput(field, typedValue)
    },
    [_onUserInput]
  )

  const onLiquidityInput = useCallback(
    (typedValue: string): void => onUserInput(Field.LIQUIDITY, typedValue),
    [onUserInput]
  )
  const onCurrencyAInput = useCallback(
    (typedValue: string): void => onUserInput(Field.CURRENCY_A, typedValue),
    [onUserInput]
  )
  const onCurrencyBInput = useCallback(
    (typedValue: string): void => onUserInput(Field.CURRENCY_B, typedValue),
    [onUserInput]
  )

  // tx sending
  const addTransaction = useTransactionAdder()

  async function onRemove() {
    if (!chainId || !library || !account || !deadline || !router) throw new Error('missing dependencies')
    const { [Field.CURRENCY_A]: currencyAmountA, [Field.CURRENCY_B]: currencyAmountB } = parsedAmounts
    if (!currencyAmountA || !currencyAmountB) {
      throw new Error('missing currency amounts')
    }

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(currencyAmountA, allowedSlippage)[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(currencyAmountB, allowedSlippage)[0],
    }

    if (!currencyA || !currencyB) throw new Error('missing tokens')
    const liquidityAmount = parsedAmounts[Field.LIQUIDITY]
    if (!liquidityAmount) throw new Error('missing liquidity amount')

    const currencyBIsETH = currencyB.isNative
    const oneCurrencyIsETH = currencyA.isNative || currencyBIsETH

    if (!tokenA || !tokenB) throw new Error('could not wrap')

    let methodNames: string[], args: Array<string | string[] | number | boolean>
    // we have approval, use normal remove liquidity
    if (approval === ApprovalState.APPROVED) {
      // removeLiquidityETH
      if (oneCurrencyIsETH) {
        methodNames = ['removeLiquidityETH', 'removeLiquidityETHSupportingFeeOnTransferTokens']
        args = [
          currencyBIsETH ? tokenA.address : tokenB.address,
          liquidityAmount.quotient.toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(),
          account,
          deadline.toHexString(),
        ]
      }
      // removeLiquidity
      else {
        methodNames = ['removeLiquidity']
        args = [
          tokenA.address,
          tokenB.address,
          liquidityAmount.quotient.toString(),
          amountsMin[Field.CURRENCY_A].toString(),
          amountsMin[Field.CURRENCY_B].toString(),
          account,
          deadline.toHexString(),
        ]
      }
    }
    // we have a signature, use permit versions of remove liquidity
    else if (signatureData !== null) {
      // removeLiquidityETHWithPermit
      if (oneCurrencyIsETH) {
        methodNames = ['removeLiquidityETHWithPermit', 'removeLiquidityETHWithPermitSupportingFeeOnTransferTokens']
        args = [
          currencyBIsETH ? tokenA.address : tokenB.address,
          liquidityAmount.quotient.toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(),
          account,
          signatureData.deadline,
          false,
          signatureData.v,
          signatureData.r,
          signatureData.s,
        ]
      }
      // removeLiquidityETHWithPermit
      else {
        methodNames = ['removeLiquidityWithPermit']
        args = [
          tokenA.address,
          tokenB.address,
          liquidityAmount.quotient.toString(),
          amountsMin[Field.CURRENCY_A].toString(),
          amountsMin[Field.CURRENCY_B].toString(),
          account,
          signatureData.deadline,
          false,
          signatureData.v,
          signatureData.r,
          signatureData.s,
        ]
      }
    } else {
      throw new Error('Attempting to confirm without approval or a signature. Please contact support.')
    }

    const safeGasEstimates: (BigNumber | undefined)[] = await Promise.all(
      methodNames.map((methodName) =>
        router.estimateGas[methodName](...args)
          .then(calculateGasMargin)
          .catch((error) => {
            console.error(`estimateGas failed`, methodName, args, error)
          setSwapState({ _attemptingTxn: false, tradeToConfirm, _showConfirm: true, swapErrorMessage: error?.data?.message, _txHash: undefined });

            return undefined
          })
      )
    ) 

    const indexOfSuccessfulEstimation = safeGasEstimates.findIndex((safeGasEstimate) =>
      BigNumber.isBigNumber(safeGasEstimate)
    )

    // all estimations failed...
    if (indexOfSuccessfulEstimation === -1) {
      console.error('This transaction would fail. Please contact support.')
    } else {
      const methodName = methodNames[indexOfSuccessfulEstimation]
      const safeGasEstimate = safeGasEstimates[indexOfSuccessfulEstimation]

      setAttemptingTxn(true)
      await router[methodName](...args, {
        gasLimit: safeGasEstimate,
      })
        .then((response: TransactionResponse) => {
          setAttemptingTxn(false)

          addTransaction(response, {
            summary: t`Remove ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(3)} ${
              currencyA?.symbol
            } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(3)} ${currencyB?.symbol}`,
          })

          setTxHash(response.hash)

          ReactGA.event({
            category: 'Liquidity',
            action: 'Remove',
            label: [currencyA?.symbol, currencyB?.symbol].join('/'),
          })
        })
        .catch((error: Error) => {
          setAttemptingTxn(false)
          // we only care if the error is something _other_ than the user rejected the tx
          console.error(error)
        })
    }
  }

  function modalHeader() {
    return (
      <AutoColumn gap={'md'} style={{ marginTop: '20px' }}>
        <RowBetween align="flex-end">
          <Text fontSize={24} fontWeight={500}>
            {parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}
          </Text>
          <RowFixed gap="4px">
            <CurrencyLogo currency={currencyA} size={'24px'} />
            <Text fontSize={24} fontWeight={500} style={{ marginLeft: '10px' }}>
              {currencyA?.symbol}
            </Text>
          </RowFixed>
        </RowBetween>
        <RowFixed>
          <Plus size="16" color={theme.text2} />
        </RowFixed>
        <RowBetween align="flex-end">
          <Text fontSize={24} fontWeight={500}>
            {parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}
          </Text>
          <RowFixed gap="4px">
            <CurrencyLogo currency={currencyB} size={'24px'} />
            <Text fontSize={24} fontWeight={500} style={{ marginLeft: '10px' }}>
              {currencyB?.symbol}
            </Text>
          </RowFixed>
        </RowBetween>

        <TYPE.italic fontSize={12} color={theme.text2} textAlign="left" padding={'12px 0 0 0'}>
          <Trans>
            Output is estimated. If the price changes by more than {allowedSlippage.toSignificant(4)}% your transaction
            will revert.
          </Trans>
        </TYPE.italic>
      </AutoColumn>
    )
  }

  function modalBottom() {
    return (
      <>
        <RowBetween>
          <Text color={theme.text2} fontWeight={500} fontSize={16}>
            <Trans>
              UNI {currencyA?.symbol}/{currencyB?.symbol} Burned
            </Trans>
          </Text>
          <RowFixed>
            <DoubleCurrencyLogo currency0={currencyA} currency1={currencyB} margin={true} />
            <Text fontWeight={500} fontSize={16}>
              {parsedAmounts[Field.LIQUIDITY]?.toSignificant(6)}
            </Text>
          </RowFixed>
        </RowBetween>
        {pair && (
          <>
            <RowBetween>
              <Text color={theme.text2} fontWeight={500} fontSize={16}>
                <Trans>Price</Trans>
              </Text>
              <Text fontWeight={500} fontSize={16} color={theme.text1}>
                1 {currencyA?.symbol} = {tokenA ? pair.priceOf(tokenA).toSignificant(6) : '-'} {currencyB?.symbol}
              </Text>
            </RowBetween>
            <RowBetween>
              <div />
              <Text fontWeight={500} fontSize={16} color={theme.text1}>
                1 {currencyB?.symbol} = {tokenB ? pair.priceOf(tokenB).toSignificant(6) : '-'} {currencyA?.symbol}
              </Text>
            </RowBetween>
          </>
        )}
        <ButtonPrimary disabled={!(approval === ApprovalState.APPROVED || signatureData !== null)} onClick={onRemove}>
          <Text fontWeight={500} fontSize={20}>
            <Trans>Confirm</Trans>
          </Text>
        </ButtonPrimary>
      </>
    )
  }

  const pendingText = t`Removing ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)} ${
    currencyA?.symbol
  } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} ${currencyB?.symbol}`

  const liquidityPercentChangeCallback = useCallback(
    (value: number) => {
      onUserInput(Field.LIQUIDITY_PERCENT, value.toString())
    },
    [onUserInput]
  )

  const oneCurrencyIsETH = currencyA?.isNative || currencyB?.isNative
  const oneCurrencyIsWETH = Boolean(
    chainId &&
      WETH9_EXTENDED[chainId] &&
      (currencyA?.equals(WETH9_EXTENDED[chainId]) || currencyB?.equals(WETH9_EXTENDED[chainId]))
  )

  const handleSelectCurrencyA = useCallback(
    (currency: Currency) => {
      alert('working')
      if (currencyIdB && currencyId(currency) === currencyIdB) {
        history.push(`/remove/v2/${currencyId(currency)}/${currencyIdA}`)
      } else {
        history.push(`/remove/v2/${currencyId(currency)}/${currencyIdB}`)
      }
    },
    [currencyIdA, currencyIdB, history]
  )
  const handleSelectCurrencyB = useCallback(
    (currency: Currency) => {
      if (currencyIdA && currencyId(currency) === currencyIdA) {
        history.push(`/remove/v2/${currencyIdB}/${currencyId(currency)}`)
      } else {
        history.push(`/remove/v2/${currencyIdA}/${currencyId(currency)}`)
      }
    },
    [currencyIdA, currencyIdB, history]
  )

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.LIQUIDITY_PERCENT, '0')
    }
    setTxHash('')
  }, [onUserInput, txHash])

  const [innerLiquidityPercentage, setInnerLiquidityPercentage] = useDebouncedChangeHandler(
    Number.parseInt(parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0)),
    liquidityPercentChangeCallback
  )
  const {   recipient } = useSwapState()
  const _handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: undefined, swapErrorMessage, _txHash, _attemptingTxn, _showConfirm })
  }, [_attemptingTxn, _showConfirm, swapErrorMessage, V2Trade, _txHash])


  const handleSwap = () => {
    return
      }

      const handleConfirmDismiss = useCallback(() => {
        setSwapState({ _showConfirm: false, tradeToConfirm, _attemptingTxn, swapErrorMessage, _txHash })
        // if there was a tx hash, we want to clear the input
        if (txHash) {
          // onUserInput(Field.INPUT, '')
        }
      }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])
     
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
          <div className='swap-main'>
            <AppBody>
              <AddRemoveTabs creating={false} adding={false} defaultSlippage={DEFAULT_REMOVE_LIQUIDITY_SLIPPAGE_TOLERANCE} />
              <Wrapper>
                <TransactionConfirmationModal
                  isOpen={showConfirm}
                  onDismiss={handleDismissConfirmation}
                  attemptingTxn={attemptingTxn}
                  hash={txHash ? txHash : ''}
                  content={() => (
                    <ConfirmationModalContent
                      title={<Trans>You will receive</Trans>}
                      onDismiss={handleDismissConfirmation}
                      topContent={modalHeader}
                      bottomContent={modalBottom}
                    />
                  )}
                  pendingText={pendingText}
                />
                  <ConfirmSwapModal
        isOpen={_showConfirm}
        trade={undefined}
        originalTrade={tradeToConfirm}
        onAcceptChanges={_handleAcceptChanges}
        attemptingTxn={attemptingTxn}
        txHash={txHash}
        recipient={recipient}
        allowedSlippage={allowedSlippage}
        onConfirm={handleSwap}
        swapErrorMessage={swapErrorMessage}
        onDismiss={handleConfirmDismiss}
        />
                <AutoColumn gap="md">
                  <BlueCard>
                    <AutoColumn gap="10px">
                      <TYPE.link fontWeight={400} color={'primaryText1'}>
                        <Trans>
                          <b>Tip:</b> Removing pool tokens converts your position back into underlying tokens at the current
                          rate, proportional to your share of the pool. Accrued fees are included in the amounts you receive.
                        </Trans>
                      </TYPE.link>
                    </AutoColumn>
                  </BlueCard>
                  <LightCard>
                    <AutoColumn gap="20px">
                      <RowBetween>
                        <Text fontWeight={500} color={'white'}>
                          <Trans>Remove Amount</Trans>
                        </Text>
                        <ClickableText
                          fontWeight={500}
                          onClick={() => {
                            setShowDetailed(!showDetailed)
                          }}
                        >
                          {showDetailed ? <Trans>Simple</Trans> : <Trans>Detailed</Trans>}
                        </ClickableText>
                      </RowBetween>
                      <Row style={{ alignItems: 'flex-end' }}>
                        <Text fontSize={72} fontWeight={500} color={'white'}>
                          {formattedAmounts[Field.LIQUIDITY_PERCENT]}%
                        </Text>
                      </Row>
                      {!showDetailed && (
                        <>
                          <Slider value={innerLiquidityPercentage} onChange={setInnerLiquidityPercentage} />
                          <RowBetween>
                            <MaxButton onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '25')} width="20%">
                              25%
                            </MaxButton>
                            <MaxButton onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '50')} width="20%">
                              50%
                            </MaxButton>
                            <MaxButton onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '75')} width="20%">
                              75%
                            </MaxButton>
                            <MaxButton onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')} width="20%">
                              Max
                            </MaxButton>
                          </RowBetween>
                        </>
                      )}
                    </AutoColumn>
                  </LightCard>
                  {!showDetailed && (
                    <>
                      <ColumnCenter>
                        <ArrowDown size="16" color={theme.text2} />
                      </ColumnCenter>
                      <LightCard>
                        <AutoColumn gap="10px">
                          <RowBetween>
                            <Text fontSize={24} fontWeight={500}>
                              {formattedAmounts[Field.CURRENCY_A] || '-'}
                            </Text>
                            <RowFixed>
                              <CurrencyLogo currency={currencyA} style={{ marginRight: '12px' }} />
                              <Text fontSize={24} fontWeight={500} id="remove-liquidity-tokena-symbol">
                                {currencyA?.symbol}
                              </Text>
                            </RowFixed>
                          </RowBetween>
                          <RowBetween>
                            <Text fontSize={24} fontWeight={500}>
                              {formattedAmounts[Field.CURRENCY_B] || '-'}
                            </Text>
                            <RowFixed>
                              <CurrencyLogo currency={currencyB} style={{ marginRight: '12px' }} />
                              <Text fontSize={24} fontWeight={500} id="remove-liquidity-tokenb-symbol">
                                {currencyB?.symbol}
                              </Text>
                            </RowFixed>
                          </RowBetween>
                          {chainId && (oneCurrencyIsWETH || oneCurrencyIsETH) ? (
                            <RowBetween style={{ justifyContent: 'flex-end' }}>
                              {oneCurrencyIsETH ? (
                                <StyledInternalLink
                                  to={`/remove/v2/${currencyA?.isNative ? WETH9_EXTENDED[chainId].address : currencyIdA}/${
                                    currencyB?.isNative ? WETH9_EXTENDED[chainId].address : currencyIdB
                                  }`}
                                >
                                  Receive WMATIC
                                </StyledInternalLink>
                              ) : oneCurrencyIsWETH ? (
                                <StyledInternalLink
                                  to={`/remove/v2/${currencyA?.equals(WETH9_EXTENDED[chainId]) ? 'MATIC' : currencyIdA}/${
                                    currencyB?.equals(WETH9_EXTENDED[chainId]) ? 'MATIC' : currencyIdB
                                  }`}
                                >
                                  Receive MATIC
                                </StyledInternalLink>
                              ) : null}
                            </RowBetween>
                          ) : null}
                        </AutoColumn>
                      </LightCard>
                    </>
                  )}

                  {showDetailed && (
                    <>
                      <CurrencyInputPanel
                        value={formattedAmounts[Field.LIQUIDITY]}
                        onUserInput={onLiquidityInput}
                        onMax={() => {
                          onUserInput(Field.LIQUIDITY_PERCENT, '100')
                        }}
                        showMaxButton={!atMaxAmount}
                        currency={pair?.liquidityToken}
                        pair={pair}
                        id="liquidity-amount"
                      />
                      <ColumnCenter>
                        <ArrowDown size="16" color={theme.text2} />
                      </ColumnCenter>
                      <CurrencyInputPanel
                        hideBalance={true}
                        value={formattedAmounts[Field.CURRENCY_A]}
                        onUserInput={onCurrencyAInput}
                        onMax={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')}
                        showMaxButton={!atMaxAmount}
                        currency={currencyA}
                        label={'Output'}
                        onCurrencySelect={handleSelectCurrencyA}
                        id="remove-liquidity-tokena"
                      />
                      <ColumnCenter>
                        <Plus size="16" color={theme.text2} />
                      </ColumnCenter>
                      <CurrencyInputPanel
                        hideBalance={true}
                        value={formattedAmounts[Field.CURRENCY_B]}
                        onUserInput={onCurrencyBInput}
                        onMax={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')}
                        showMaxButton={!atMaxAmount}
                        currency={currencyB}
                        label={'Output'}
                        onCurrencySelect={handleSelectCurrencyB}
                        id="remove-liquidity-tokenb"
                      />
                    </>
                  )}
                  {pair && (
                    <div style={{ padding: '10px 20px', color: 'white', }}>
                      <RowBetween>
                        <Trans>Price:</Trans>
                        <div>
                          1 {currencyA?.symbol} = {tokenA ? pair.priceOf(tokenA).toSignificant(6) : '-'} {currencyB?.symbol}
                        </div>
                      </RowBetween>
                      <RowBetween>
                        <div />
                        <div>
                          1 {currencyB?.symbol} = {tokenB ? pair.priceOf(tokenB).toSignificant(6) : '-'} {currencyA?.symbol}
                        </div>
                      </RowBetween>
                    </div>
                  )}
                  <div style={{ position: 'relative' }}>
                    {!account ? (
                      <ButtonLight onClick={toggleWalletModal}>
                        <Trans>Connect Wallet</Trans>
                      </ButtonLight>
                    ) : (
                      <RowBetween>
                        <ButtonConfirmed
                          onClick={onAttemptToApprove}
                          confirmed={approval === ApprovalState.APPROVED || signatureData !== null}
                          disabled={approval !== ApprovalState.NOT_APPROVED || signatureData !== null}
                          mr="0.5rem"
                          fontWeight={500}
                          fontSize={16}
                        >
                          {approval === ApprovalState.PENDING ? (
                            <Dots>
                              <Trans>Approving</Trans>
                            </Dots>
                          ) : approval === ApprovalState.APPROVED || signatureData !== null ? (
                            <Trans>Approved</Trans>
                          ) : (
                            <Trans>Approve</Trans>
                          )}
                        </ButtonConfirmed>
                        <ButtonError
                          onClick={() => {
                            setShowConfirm(true)
                          }}
                          disabled={!isValid || (signatureData === null && approval !== ApprovalState.APPROVED)}
                          error={!isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]}
                        >
                          <Text fontSize={16} fontWeight={500}>
                            {error || <Trans>Remove</Trans>}
                          </Text>
                        </ButtonError>
                      </RowBetween>
                    )}
                  </div>
                </AutoColumn>
              </Wrapper>
            </AppBody>
          </div>
          </div>
        </div>
      </div>

      {pair ? (
        <AutoColumn style={{ minWidth: '20rem', width: '100%', maxWidth: '400px', marginTop: '1rem' }}>
          <MinimalPositionCard showUnwrapped={oneCurrencyIsWETH} pair={pair} />
        </AutoColumn>
      ) : null}
    </>
  )
}
