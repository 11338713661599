import { useEffect, useState } from "react";
import styled from "styled-components";
import Laxcelogo from '../../assets/images/laxce-logo.svg'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  baccground-colour: #f0f0f0;

`;

//Create functional component
export function PageLoader() {
  const [showLoader, setShowLoader] = useState(true); 

  useEffect(() => {
    // Disable scroll and fix body position when loader is visible
    if (showLoader) {
      document.body.style.overflow = 'hidden'; 
      document.body.style.position = 'fixed';  
      document.body.style.top = '0';  
      document.body.style.width = '100%';  
    } else {
      document.body.style.overflow = '';      
      document.body.style.position = '';     
      document.body.style.top = '';          
      document.body.style.width = '';       
    }

    // Cleanup the effect by resetting the body styles when the component unmounts
    return () => {
      document.body.style.overflow = '';    
      document.body.style.position = '';     
      document.body.style.top = '';           
      document.body.style.width = '';        
    };
  }, [showLoader]); // This runs whenever showLoader changes

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false); // Hide loader after 2 seconds
    }, 2000);

    // Cleanup the timer if component unmounts
    return () => clearTimeout(timer);
  }, []);

  return (
    <Container>
      {showLoader && (
        <div className="loader-main">
          <img src={Laxcelogo} alt="Loading..." />
        </div>
      )}
    </Container>
  );
}