import axios from 'axios';
import { BASE_URL } from 'constants/index';
import {ethers} from "ethers"

export const listPair=async()=>{
try {
    const {data}=await axios.get(`${BASE_URL}/list-pair`);
    if(data)return data;
} catch (error) {
    throw error;
}
}

export const swapWithOneInch = async (baseTokenAddress,quoteTokenAddress,account,amount,decimal) => {
    try {
        const {data}=await axios.get(`${BASE_URL}/swap?baseTokenAddress=${baseTokenAddress}&quoteTokenAddress=${quoteTokenAddress}&account=${account}&amount=${amount}&decimal=${decimal}`)
      if(data) return data;
    } catch (error) {
      console.error("Error in swapWithOneInch:", error);
      throw error;
    }
  };

  export const tokenPriceWithOneInch = async (baseTokenAddress,quoteTokenAddress,amount,decimals) => {
    if(!amount || amount <= 0 || !baseTokenAddress || !quoteTokenAddress)return
    try {
      const amountString = amount.toString();
      const amountInWei=ethers.utils.parseUnits(amountString, decimals).toString()
        const {data}=await axios.get(`${BASE_URL}/get-price?baseTokenAddress=${baseTokenAddress}&quoteTokenAddress=${quoteTokenAddress}&amount=${amountInWei}`)
      if(data){
        return data;
      }else {
        throw new Error("No data returned from the API.");
      } 
    } catch (error) {
      console.error("Error in swapWithOneInch:", error);
      throw error;
    }
  };