import { BigNumber } from "@ethersproject/bignumber";
import { TransactionResponse } from "@ethersproject/providers";
import { Currency, CurrencyAmount, Percent, TradeType } from "@uniswap/sdk-core";
import { useCallback, useContext, useState, useEffect } from "react";
import { Plus } from "react-feather";
import ReactGA from "react-ga";
import { RouteComponentProps } from "react-router-dom";
import { Text } from "rebass";
import { ThemeContext } from "styled-components";
import { ButtonError, ButtonLight, ButtonPrimary } from "../../components/Button";
import { BlueCard, LightCard } from "../../components/Card";
import { AutoColumn, ColumnCenter } from "../../components/Column";
import TransactionConfirmationModal, {
  ConfirmationModalContent,
} from "../../components/TransactionConfirmationModal";
import CurrencyInputPanel from "../../components/CurrencyInputPanel";
import DoubleCurrencyLogo from "../../components/DoubleLogo";
import { AddRemoveTabs } from "../../components/NavigationTabs";
import { MinimalPositionCard } from "../../components/PositionCard";
import Row, { RowBetween, RowFlat } from "../../components/Row";
import { Trade as V2Trade } from "@uniswap/v2-sdk";
import { Trade as V3Trade } from "@uniswap/v3-sdk";
import { ZERO_PERCENT } from "../../constants/misc";
import { WETH9_EXTENDED } from "../../constants/tokens";
import { useV2RouterContract } from "../../hooks/useContract";
import { PairState } from "../../hooks/useV2Pairs";
import { useActiveWeb3React } from "../../hooks/web3";
import { useCurrency } from "../../hooks/Tokens";
import { ApprovalState, useApproveCallback } from "../../hooks/useApproveCallback";
import { useIsSwapUnsupported } from "../../hooks/useIsSwapUnsupported";
import useTransactionDeadline from "../../hooks/useTransactionDeadline";
import { useWalletModalToggle } from "../../state/application/hooks";
import { Field } from "../../state/mint/actions";
import { useDerivedMintInfo, useMintActionHandlers, useMintState } from "../../state/mint/hooks";

import { useTransactionAdder } from "../../state/transactions/hooks";
import { useIsExpertMode, useUserSlippageToleranceWithDefault } from "../../state/user/hooks";
import { TYPE } from "../../theme";
import { calculateGasMargin } from "../../utils/calculateGasMargin";
import { calculateSlippageAmount } from "../../utils/calculateSlippageAmount";
import { maxAmountSpend } from "../../utils/maxAmountSpend";
import AppBody from "../AppBody";
import { Dots, Wrapper } from "../Pool/styleds";
import { ConfirmAddModalBottom } from "./ConfirmAddModalBottom";
import { currencyId } from "../../utils/currencyId";
import { PoolPriceBar } from "./PoolPriceBar";
import UnsupportedCurrencyFooter from "components/swap/UnsupportedCurrencyFooter";
import { t, Trans } from "@lingui/macro";
import { useSwapActionHandlers, useSwapState } from "state/swap/hooks";
import ConfirmSwapModal from "components/swap/ConfirmSwapModal";
import { swapErrorToUserReadableMessage } from "hooks/useSwapCallback";

import { ethers } from "ethers";
import RouterABI from "../Swap/RouterABI.json";
import { flexDirection, overflow, width } from "styled-system";

declare var window: any;

const DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE = new Percent(50, 10_000);

export default function AddLiquidity({
  match: {
    params: { currencyIdA, currencyIdB },
  },
  history,
}: RouteComponentProps<{ currencyIdA?: string; currencyIdB?: string }>) {

  const { account, chainId, library } = useActiveWeb3React();
  const theme = useContext(ThemeContext);

  const currencyA = useCurrency(currencyIdA);
  const currencyB = useCurrency(currencyIdB);

  const oneCurrencyIsWETH = Boolean(
    chainId &&
      ((currencyA && currencyA.equals(WETH9_EXTENDED[chainId])) ||
        (currencyB && currencyB.equals(WETH9_EXTENDED[chainId])))
  );

  const toggleWalletModal = useWalletModalToggle(); // toggle wallet when disconnected

  const expertMode = useIsExpertMode();

  // mint state
  const { independentField, typedValue, otherTypedValue } = useMintState();
  const {
    dependentField,
    currencies,
    pair,
    pairState,
    currencyBalances,
    parsedAmounts,
    price,
    noLiquidity,
    liquidityMinted,
    poolTokenPercentage,
    error,
  } = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined);

  console.log(pairState,'pairState')


  const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity);

  const isValid = !error;

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false); // clicked confirm

  // txn values
  const deadline = useTransactionDeadline(); // custom from users settings
  const allowedSlippage = useUserSlippageToleranceWithDefault(DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE); // custom from users
  const [txHash, setTxHash] = useState<string>("");

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: noLiquidity
      ? otherTypedValue
      : parsedAmounts[dependentField]?.toSignificant(6) ?? "",
  };

  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [
    Field.CURRENCY_A,
    Field.CURRENCY_B,
  ].reduce((accumulator, field) => {
    return {
      ...accumulator,
      [field]: maxAmountSpend(currencyBalances[field]),
    };
  }, {});

  const atMaxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [
    Field.CURRENCY_A,
    Field.CURRENCY_B,
  ].reduce((accumulator, field) => {
    return {
      ...accumulator,
      [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? "0"),
    };
  }, {});

  const router = useV2RouterContract();

  // check whether the user has approved the router on the tokens
  const [approvalA, approveACallback] = useApproveCallback(
    parsedAmounts[Field.CURRENCY_A],
    router?.address
  );
  const [approvalB, approveBCallback] = useApproveCallback(
    parsedAmounts[Field.CURRENCY_B],
    router?.address
  );

  const addTransaction = useTransactionAdder();

  async function onAdd() {
    if (!chainId || !library || !account || !router) return;

    const { [Field.CURRENCY_A]: parsedAmountA, [Field.CURRENCY_B]: parsedAmountB } = parsedAmounts;
    if (!parsedAmountA || !parsedAmountB || !currencyA || !currencyB || !deadline) {
      return;
    }

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(
        parsedAmountA,
        noLiquidity ? ZERO_PERCENT : allowedSlippage
      )[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(
        parsedAmountB,
        noLiquidity ? ZERO_PERCENT : allowedSlippage
      )[0],
    };

    let estimate,
      method: (...args: any) => Promise<TransactionResponse>,
      args: Array<string | string[] | number>,
      value: BigNumber | null;
    if (currencyA.isNative || currencyB.isNative) {
      const tokenBIsETH = currencyB.isNative;
      estimate = router.estimateGas.addLiquidityETH;
      method = router.addLiquidityETH;
      args = [
        (tokenBIsETH ? currencyA : currencyB)?.wrapped?.address ?? "", // token
        (tokenBIsETH ? parsedAmountA : parsedAmountB).quotient.toString(), // token desired
        amountsMin[tokenBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(), // token min
        amountsMin[tokenBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(), // eth min
        account,
        deadline.toHexString(),
      ];
      value = BigNumber.from((tokenBIsETH ? parsedAmountB : parsedAmountA).quotient.toString());
    } else {
      estimate = router.estimateGas.addLiquidity;
      method = router.addLiquidity;
      args = [
        currencyA?.wrapped?.address ?? "",
        currencyB?.wrapped?.address ?? "",
        parsedAmountA.quotient.toString(),
        parsedAmountB.quotient.toString(),
        amountsMin[Field.CURRENCY_A].toString(),
        amountsMin[Field.CURRENCY_B].toString(),
        account,
        deadline.toHexString(),
      ];
      value = null;
    }

    setAttemptingTxn(true);
    await estimate(...args, value ? { value } : {})
      .then((estimatedGasLimit) =>
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit),
        }).then((response) => {
          setAttemptingTxn(false);

          addTransaction(response, {
            summary: t`Add ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(3)} ${
              currencies[Field.CURRENCY_A]?.symbol
            } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(3)} ${
              currencies[Field.CURRENCY_B]?.symbol
            }`,
          });

          setTxHash(response.hash);

          ReactGA.event({
            category: "Liquidity",
            action: "Add",
            label: [
              currencies[Field.CURRENCY_A]?.symbol,
              currencies[Field.CURRENCY_B]?.symbol,
            ].join("/"),
          });
        })
      )
      .catch((error) => {
        setAttemptingTxn(false);

        // we only care if the error is something _other_ than the user rejected the t
        if (error?.code !== 4001) {
          console.error(error);
          setSwapState({
            _attemptingTxn: false,
            tradeToConfirm: undefined,
            _showConfirm: true,
            swapErrorMessage: error?.data?.message,
            _txHash: undefined,
          });

          return;
        }
      });
  }

  const modalHeader = () => {
    return noLiquidity ? (
      <AutoColumn
        gap="20px"
        style={{
          display: "flex",
          overflow: "hidden",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <LightCard mt="20px" borderRadius="20px">
          <RowFlat>
            <Text
              fontSize="32px"
              fontWeight={500}
              lineHeight="42px"
              marginRight={10}
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {currencies[Field.CURRENCY_A]?.symbol + "/" + currencies[Field.CURRENCY_B]?.symbol}
            </Text>
            <DoubleCurrencyLogo
              currency0={currencies[Field.CURRENCY_A]}
              currency1={currencies[Field.CURRENCY_B]}
              size={30}
            />
          </RowFlat>
        </LightCard>
      </AutoColumn>
    ) : (
      <AutoColumn
        gap="20px"
        style={{
          display: "flex",
          overflow: "hidden",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <RowFlat style={{ marginTop: "20px" }}>
          <Text
            fontSize="26px"
            fontWeight={500}
            lineHeight="42px"
            marginRight={30}
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {liquidityMinted?.toSignificant(6)}
          </Text>
          <DoubleCurrencyLogo
            currency0={currencies[Field.CURRENCY_A]}
            currency1={currencies[Field.CURRENCY_B]}
            size={30}
          />
        </RowFlat>
        <Row>
          <Text fontSize="24px">
            {currencies[Field.CURRENCY_A]?.symbol +
              "/" +
              currencies[Field.CURRENCY_B]?.symbol +
              " Pool Tokens"}
          </Text>
        </Row>
        <TYPE.italic fontSize={12} textAlign="left" padding={"8px 0 0 0 "}>
          <Trans>
            Output is estimated. If the price changes by more than{" "}
            {allowedSlippage.toSignificant(4)}% your transaction will revert.
          </Trans>
        </TYPE.italic>
      </AutoColumn>
    );
  };

  const modalBottom = () => {
    return (
      <ConfirmAddModalBottom
        price={price}
        currencies={currencies}
        parsedAmounts={parsedAmounts}
        noLiquidity={noLiquidity}
        onAdd={onAdd}
        poolTokenPercentage={poolTokenPercentage}
      />
    );
  };

  const pendingText = t`Supplying ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)} ${
    currencies[Field.CURRENCY_A]?.symbol
  } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} ${
    currencies[Field.CURRENCY_B]?.symbol
  }`;

  const [contractData, setContractData] = useState(null);
  const [isRoleGranted, setIsRoleGranted] = useState(false);
  const contractAddress = "0xCb227D0A84bCB40942c7fe2d8750ceab1F750B77";
  const authRole: any = "0x992f44c638e29d2ba83ec39e831315f29d6b7188a87e5bf8311ef8074cd46e2d";
  let provider: any;
  let contract: any;
  // Create a provider and contract instance outside the component
  if (typeof window.ethereum !== "undefined") {
    provider = new ethers.providers.Web3Provider(window.ethereum);
    contract = new ethers.Contract(contractAddress, RouterABI, provider.getSigner());

    // Now you can use 'provider' to interact with Ethereum
  } else {
    console.log("MetaMask is not installed");
  }

  const handleCurrencyASelect = useCallback(
    async (currencyA: Currency) => {
      const newCurrencyIdA = currencyId(currencyA);
      if (newCurrencyIdA === currencyIdB) {
        history.push(`/add/v2/${currencyIdB}/${currencyIdA}`);
      } else {
        history.push(`/add/v2/${newCurrencyIdA}/${currencyIdB}`);
      }
      if (newCurrencyIdA !== contractAddress) {
        return;
      }
      try {
        const accounts = await provider.send("eth_requestAccounts", []);
        console.log(authRole, accounts[0]);
        const data = await contract.hasRole(authRole, accounts[0], {
          gasLimit: 5000000000,
        });
        setIsRoleGranted(data);
        if (data) {
          return;
        }
        setSwapState({
          _attemptingTxn: false,
          tradeToConfirm,
          _showConfirm: true,
          swapErrorMessage:
            "You are not allowed to perform this action on this token ( Contact Token Admin )",
          _txHash: undefined,
        });

        setContractData(data.toString());
      } catch (error) {
        console.error("Error handling input selection:", error);
      }
    },
    [currencyIdB, history, currencyIdA]
  );

  const handleCurrencyBSelect = useCallback(
    async (currencyB: Currency) => {
      const newCurrencyIdB = currencyId(currencyB);
      if (currencyIdA === newCurrencyIdB) {
        if (currencyIdB) {
          history.push(`/add/v2/${currencyIdB}/${newCurrencyIdB}`);
        } else {
          history.push(`/add/v2/${newCurrencyIdB}`);
        }
      } else {
        history.push(`/add/v2/${currencyIdA ? currencyIdA : "MATIC"}/${newCurrencyIdB}`);
      }
      if (newCurrencyIdB !== contractAddress) {
        return;
      }
      try {
        const accounts = await provider.send("eth_requestAccounts", []);
        console.log(authRole, accounts[0]);
        const data = await contract.hasRole(authRole, accounts[0], {
          gasLimit: 5000000000,
        });
        console.log(data);
        setIsRoleGranted(data);
        if (data) {
          return;
        }
        setSwapState({
          _attemptingTxn: false,
          tradeToConfirm,
          _showConfirm: true,
          swapErrorMessage:
            "You are not allowed to perform this action on this token ( Contact Token Admin )",
          _txHash: undefined,
        });

        // setContractData(data.toString());
      } catch (error) {
        console.error("Error handling input selection:", error);
      }
    },
    [currencyIdA, history, currencyIdB]
  );

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false);
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onFieldAInput("");
    }
    setTxHash("");
  }, [onFieldAInput, txHash]);

  const isCreate = history.location.pathname.includes("/create");

  const addIsUnsupported = useIsSwapUnsupported(currencies?.CURRENCY_A, currencies?.CURRENCY_B);
  const { recipient } = useSwapState();
  const [
    { _showConfirm, tradeToConfirm, swapErrorMessage, _attemptingTxn, _txHash },
    setSwapState,
  ] = useState<{
    _showConfirm: boolean;
    tradeToConfirm:
      | V2Trade<Currency, Currency, TradeType>
      | V3Trade<Currency, Currency, TradeType>
      | undefined;
    _attemptingTxn: boolean;
    swapErrorMessage: string | undefined;
    _txHash: string | undefined;
  }>({
    _showConfirm: false,
    tradeToConfirm: undefined,
    _attemptingTxn: false,
    swapErrorMessage: undefined,
    _txHash: undefined,
  });
  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } =
    useSwapActionHandlers();

  const handleAcceptChanges = useCallback(() => {
    setSwapState({
      tradeToConfirm: undefined,
      swapErrorMessage,
      _txHash,
      _attemptingTxn,
      _showConfirm,
    });
  }, [_attemptingTxn, showConfirm, swapErrorMessage, V2Trade, _txHash]);

  // Create a provider and contract instance outside the component
  useEffect(() => {
    const fetchData = async () => {
      if (currencyIdA === contractAddress || currencyIdB === contractAddress) {
        try {
          // Create a provider and contract instance outside the component
          let provider: any;
          let contract: any;
          // Create a provider and contract instance outside the component
          if (typeof window.ethereum !== "undefined") {
            provider = new ethers.providers.Web3Provider(window.ethereum);
            contract = new ethers.Contract(contractAddress, RouterABI, provider.getSigner());

            // Now you can use 'provider' to interact with Ethereum
          } else {
            console.log("MetaMask is not installed");
            return;
          }

          const accounts = await provider.send("eth_requestAccounts", []);
          console.log(authRole, accounts[0]);
          const data = await contract.hasRole(authRole, accounts[0], {
            gasLimit: 5000000000,
          });
          console.log(data);
          setIsRoleGranted(data);
          if (data) {
            return;
          }
          setSwapState({
            _attemptingTxn: false,
            tradeToConfirm,
            _showConfirm: true,
            swapErrorMessage:
              "You are not allowed to perform this action on this token ( Contact Token Admin )",
            _txHash: undefined,
          });

          setContractData(data.toString());
        } catch (error) {
          console.error("Error handling input selection:", error);
        }
      }
    };
    if (typeof window.ethereum !== "undefined") {
      window.ethereum.on("accountsChanged", async function (accounts) {
        fetchData();
      });
    }

    fetchData(); // Call fetchData when the component initializes
  }, []); // Trigger the effect only once on mount

  const handleSwap = () => {
    return;
  };

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({
      _showConfirm: false,
      tradeToConfirm,
      _attemptingTxn,
      swapErrorMessage,
      _txHash,
    });
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      // onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash]);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <div className="swap-main">
              <AppBody>
                <AddRemoveTabs
                  creating={isCreate}
                  adding={true}
                  defaultSlippage={DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE}
                />
                <Wrapper>
                  <TransactionConfirmationModal
                    isOpen={showConfirm}
                    onDismiss={handleDismissConfirmation}
                    attemptingTxn={attemptingTxn}
                    hash={txHash}
                    content={() => (
                      <ConfirmationModalContent
                        title={
                          noLiquidity ? (
                            <Trans>You are creating a pool</Trans>
                          ) : (
                            <Trans>You will receive</Trans>
                          )
                        }
                        onDismiss={handleDismissConfirmation}
                        topContent={modalHeader}
                        bottomContent={modalBottom}
                      />
                    )}
                    pendingText={pendingText}
                    currencyToAdd={pair?.liquidityToken}
                  />
                  <ConfirmSwapModal
                    isOpen={_showConfirm}
                    trade={undefined}
                    originalTrade={tradeToConfirm}
                    onAcceptChanges={handleAcceptChanges}
                    attemptingTxn={attemptingTxn}
                    txHash={txHash}
                    recipient={recipient}
                    allowedSlippage={allowedSlippage}
                    onConfirm={handleSwap}
                    swapErrorMessage={swapErrorMessage}
                    onDismiss={handleConfirmDismiss}
                  />
                  <AutoColumn gap="20px">
                    {noLiquidity ||
                      (isCreate ? (
                        <ColumnCenter>
                          <BlueCard>
                            <AutoColumn gap="10px">
                              <TYPE.link fontWeight={600} color={"primaryText1"}>
                                <Trans>You are the first liquidity provider.</Trans>
                              </TYPE.link>
                              <TYPE.link fontWeight={400} color={"primaryText1"}>
                                <Trans>
                                  The ratio of tokens you add will set the price of this pool.
                                </Trans>
                              </TYPE.link>
                              <TYPE.link fontWeight={400} color={"primaryText1"}>
                                <Trans>
                                  Once you are happy with the rate click supply to review.
                                </Trans>
                              </TYPE.link>
                            </AutoColumn>
                          </BlueCard>
                        </ColumnCenter>
                      ) : (
                        <ColumnCenter>
                          <BlueCard>
                            <AutoColumn gap="10px">
                              <TYPE.link fontWeight={400} color={"primaryText1"}>
                                <Trans>
                                  <b>
                                    <Trans>Tip:</Trans>
                                  </b>{" "}
                                  When you add liquidity, you will receive pool tokens representing
                                  your position. These tokens automatically earn fees proportional
                                  to your share of the pool, and can be redeemed at any time.
                                </Trans>
                              </TYPE.link>
                            </AutoColumn>
                          </BlueCard>
                        </ColumnCenter>
                      ))}
                    <CurrencyInputPanel
                      value={formattedAmounts[Field.CURRENCY_A]}
                      onUserInput={onFieldAInput}
                      onMax={() => {
                        onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? "");
                      }}
                      onCurrencySelect={handleCurrencyASelect}
                      showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
                      currency={currencies[Field.CURRENCY_A]}
                      id="add-liquidity-input-tokena"
                      showCommonBases
                    />
                    <ColumnCenter>
                      <Plus size="16" color={theme.text2} />
                    </ColumnCenter>
                    <CurrencyInputPanel
                      value={formattedAmounts[Field.CURRENCY_B]}
                      onUserInput={onFieldBInput}
                      onCurrencySelect={handleCurrencyBSelect}
                      onMax={() => {
                        onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? "");
                      }}
                      showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
                      currency={currencies[Field.CURRENCY_B]}
                      id="add-liquidity-input-tokenb"
                      showCommonBases
                    />
                    {currencies[Field.CURRENCY_A] &&
                      currencies[Field.CURRENCY_B] &&
                      pairState !== PairState.INVALID && (
                        <>
                          <LightCard padding="0px" borderRadius={"20px"}>
                            <RowBetween padding="1rem">
                              <TYPE.subHeader fontWeight={500} fontSize={14} color={"#fff"}>
                                {noLiquidity ? (
                                  <Trans>Initial prices and pool share</Trans>
                                ) : (
                                  <Trans>Prices and pool share</Trans>
                                )}
                              </TYPE.subHeader>
                            </RowBetween>{" "}
                            <LightCard padding="1rem" borderRadius={"20px"}>
                              <PoolPriceBar
                                currencies={currencies}
                                poolTokenPercentage={poolTokenPercentage}
                                noLiquidity={noLiquidity}
                                price={price}
                              />
                            </LightCard>
                          </LightCard>
                        </>
                      )}

                    {addIsUnsupported ? (
                      <ButtonPrimary disabled={true}>
                        <TYPE.main mb="4px">
                          <Trans>Unsupported Asset</Trans>
                        </TYPE.main>
                      </ButtonPrimary>
                    ) : !account ? (
                      <ButtonLight onClick={toggleWalletModal}>
                        <Trans>Connect Wallet</Trans>
                      </ButtonLight>
                    ) : (
                      <AutoColumn gap={"md"}>
                        {(approvalA === ApprovalState.NOT_APPROVED ||
                          approvalA === ApprovalState.PENDING ||
                          approvalB === ApprovalState.NOT_APPROVED ||
                          approvalB === ApprovalState.PENDING) &&
                          isValid && (
                            <RowBetween>
                              {approvalA !== ApprovalState.APPROVED && (
                                <ButtonPrimary
                                  onClick={approveBCallback}
                                  disabled={approvalA === ApprovalState.PENDING}
                                  width={approvalB !== ApprovalState.APPROVED ? "48%" : "100%"}
                                >
                                  {approvalA === ApprovalState.PENDING ? (
                                    <Dots>
                                      <Trans>
                                        Approving {currencies[Field.CURRENCY_A]?.symbol}
                                      </Trans>
                                    </Dots>
                                  ) : (
                                    <Trans>Approve {currencies[Field.CURRENCY_A]?.symbol}</Trans>
                                  )}
                                </ButtonPrimary>
                              )}
                              {approvalB !== ApprovalState.APPROVED && (
                                <ButtonPrimary
                                  onClick={approveBCallback}
                                  disabled={approvalB === ApprovalState.PENDING}
                                  width={approvalA !== ApprovalState.APPROVED ? "48%" : "100%"}
                                >
                                  {approvalB === ApprovalState.PENDING ? (
                                    <Dots>
                                      <Trans>
                                        Approving {currencies[Field.CURRENCY_B]?.symbol}
                                      </Trans>
                                    </Dots>
                                  ) : (
                                    <Trans>Approve {currencies[Field.CURRENCY_B]?.symbol}</Trans>
                                  )}
                                </ButtonPrimary>
                              )}
                            </RowBetween>
                          )}
                        {(
                          <ButtonError
                            onClick={() => {
                              if (expertMode) {
                                return onAdd(); // Add liquidity in expert mode
                              } else {
                                return setShowConfirm(true); // Show confirmation modal
                              }
                            }}
                            disabled={
                              !isValid ||
                              approvalA !== ApprovalState.APPROVED ||
                              approvalB !== ApprovalState.APPROVED
                            }
                            error={
                              !isValid &&
                              !!parsedAmounts[Field.CURRENCY_A] &&
                              !!parsedAmounts[Field.CURRENCY_B]
                            }
                          >
                            <Text fontSize={20} fontWeight={500}>
                              <Text fontSize={20} fontWeight={500}>
                                {error ? error : <Trans>Supply</Trans>}
                              </Text>
                            </Text>
                          </ButtonError>
                        )}
                      </AutoColumn>
                    )}
                  </AutoColumn>
                </Wrapper>
              </AppBody>
            </div>
          </div>
        </div>
      </div>
      {!addIsUnsupported ? (
        pair && !noLiquidity && pairState !== PairState.INVALID ? (
          <AutoColumn
            style={{
              minWidth: "20rem",
              width: "100%",
              maxWidth: "400px",
              marginTop: "1rem",
            }}
          >
            <MinimalPositionCard showUnwrapped={oneCurrencyIsWETH} pair={pair} />
          </AutoColumn>
        ) : null
      ) : (
        <UnsupportedCurrencyFooter
          show={addIsUnsupported}
          currencies={[currencies.CURRENCY_A, currencies.CURRENCY_B]}
        />
      )}
    </>
  );
}
