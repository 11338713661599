import axios from "axios";
import { BASE_URL } from 'constants/index';

export const priorityStatus=async(baseTokenAddress:string|undefined,quoteTokenAddress:string|undefined)=>{
    try {
        const {data}=await axios.get(`${BASE_URL}/get-pair?baseTokenAddress=${baseTokenAddress}&quoteTokenAddress=${quoteTokenAddress}`);
        if(data)return data
    } catch (error) {
        throw error
    }
}

export const checkAllowance=async(baseTokenAddress:string|undefined,account:string|undefined,amount:string|undefined,decimal:number|undefined)=>{
try {
    const {data}=await axios.get(`${BASE_URL}/check-allowance?baseTokenAddress=${baseTokenAddress}&account=${account}&amount=${amount}&decimal=${decimal}`);
    if(data)return data
} catch (error) {
    throw error
}
}

export const approve=async(baseTokenAddress,amount,decimal)=>{
    try {
        const {data}=await axios.get(`${BASE_URL}/approve?baseTokenAddress=${baseTokenAddress}&amount=${amount}&decimal=${decimal}`);
        if(data) return data
    } catch (error) {
        throw error
    }
}