import Footerlogo from "../../assets/images/footer-logo1.svg";
import { Trans, t, } from "@lingui/macro";
import { useLingui } from '@lingui/react';

export default function Footer() {
    const { i18n } = useLingui();
  return (
    <div className="footer-section">
      <div className="container">
        <div className="footer-head">
          <div className="row">
            <div className="col-lg-3">
              <div>
                <img src={Footerlogo} alt="footer log" className="footer-logo"></img>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="row">
                <div className="col-lg-4">
                  <div className="footer-title">
                    <h3>
                      <Trans>Stay in the loop</Trans>
                    </h3>
                    <p>
                      <Trans>Sign up for Laxce updates and news</Trans>
                    </p>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="foot-btn">
                  <input 
      type="text" 
      placeholder={t`Enter your email address`}
    />
                    <button className="reg-btn">
                      <Trans>Register Now</Trans>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-divider"></div>
        <div className="footer-menu">
          <div className="row gy-3 gy-md-4">
            <div className="col-lg-5">
              <h4>
                <Trans>Legal</Trans>
              </h4>
              <ul className="menu-list">
                <li>
                  <a href="">
                    <Trans>Cookie policy</Trans>
                  </a>
                </li>
                <li>
                  <a href="">
                    <Trans>Privacy Policy</Trans>
                  </a>
                </li>
                <li>
                  <a href="">
                    <Trans>Terms of use</Trans>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-4">
              <h4>
                <Trans>Community</Trans>
              </h4>
              <ul className="menu-list">
                <li>
                  <a href="">
                    <Trans>Blogs and Videos</Trans>
                  </a>
                </li>
                <li>
                  <a href="">
                    <Trans>Learn</Trans>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3">
              <div className="text-start text-lg-end">
                <h4>
                  {" "}
                  <Trans>Join the conversation</Trans>
                </h4>
                <div className="social-links">
                  <a href="https://discord.com/invite/D6u5uERy" target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M21.8501 10.8688C22.0425 10.4335 22.2552 10.0065 22.4878 9.58795C22.5349 9.50416 22.5789 9.48832 22.6816 9.50705C24.3308 9.84578 25.9681 10.3528 27.6037 10.9684C27.6962 11.0018 27.8117 11.0753 27.8642 11.1634C30.4245 15.0442 32.0784 19.5159 31.5781 25.1197C31.5649 25.33 31.524 25.3656 31.3756 25.473C29.5013 26.8034 27.5133 27.8617 25.3377 28.471C25.1159 28.5381 25.0445 28.4846 24.9539 28.353C24.5148 27.7084 24.1258 27.047 23.7436 26.3831C24.5059 26.0878 25.2286 25.7591 25.867 25.3595L25.3585 24.9274C23.9704 25.7833 21.6408 26.345 19.0007 26.345C16.3605 26.345 14.0309 25.7833 12.6428 24.9274L12.1343 25.3595C12.7727 25.7593 13.4954 26.0878 14.2577 26.3831C13.8755 27.0468 13.4865 27.7082 13.0474 28.353C12.9568 28.4846 12.8854 28.5381 12.6636 28.471C10.488 27.8617 8.49997 26.8034 6.62567 25.473C6.47725 25.3656 6.43645 25.33 6.42319 25.1197C5.92287 19.5161 7.57684 15.0444 10.1371 11.1636C10.1896 11.0755 10.3051 11.0019 10.3976 10.9686C12.033 10.3528 13.6703 9.84578 15.3195 9.50705C15.4222 9.48849 15.4663 9.50433 15.5133 9.58795C15.7459 10.0065 15.9586 10.4333 16.151 10.8688C16.9951 10.7215 17.9665 10.6373 19.0005 10.6373C20.0345 10.6373 21.006 10.7215 21.8499 10.8688H21.8501ZM14.7786 17.4991C13.5342 17.4991 12.5255 18.612 12.5255 19.9846C12.5255 21.3572 13.5343 22.4701 14.7786 22.4701C16.0228 22.4701 17.0317 21.3572 17.0317 19.9846C17.0317 18.612 16.0228 17.4991 14.7786 17.4991ZM23.2227 17.4991C21.9783 17.4991 20.9696 18.612 20.9696 19.9846C20.9696 21.3572 21.9785 22.4701 23.2227 22.4701C24.467 22.4701 25.4758 21.3572 25.4758 19.9846C25.4758 18.612 24.467 17.4991 23.2227 17.4991Z"
                        fill="currentColor"
                      />
                    </svg>
                  </a>
                  <a href="https://x.com/LAXCECRYPTO" target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                    >
                      <path
                        d="M5.34351 7.38861L15.5319 20.4883L5.2793 31.1386H7.58694L16.5633 21.8139L23.8157 31.1386H31.6682L20.9063 17.3023L30.4495 7.38861H28.1418L19.8753 15.9762L13.196 7.38861H5.34351ZM8.737 9.02336H12.3444L28.2743 29.5043H24.6669L8.737 9.02336Z"
                        fill="currentColor"
                      />
                    </svg>
                  </a>
                  <a href="https://t.me/Laxce_L2" target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                    >
                      <path
                        d="M5.30869 20.76L27.9761 11.2048C29.0697 10.7439 30.2455 11.6609 30.0646 12.8341L27.3087 30.69C27.0901 32.1062 25.4714 32.8129 24.2841 32.0104L16.1616 26.5198C15.5233 26.0883 15.4458 25.1785 16.002 24.6452L23.71 17.2535C23.9109 17.0609 23.8994 16.7363 23.6851 16.5583C23.5258 16.4262 23.2988 16.4147 23.127 16.5302L12.9163 23.4001C11.994 24.0207 10.8395 24.1879 9.77922 23.8545L5.39015 22.4746C4.57816 22.2194 4.52483 21.0904 5.30934 20.76H5.30869Z"
                        fill="currentColor"
                      />
                    </svg>
                  </a>
                  <a href="" target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                    >
                      <path
                        d="M31.1245 13.3632C30.8204 12.2652 29.9291 11.3997 28.7987 11.104C26.7337 10.5553 18.4734 10.5553 18.4734 10.5553C18.4734 10.5553 10.2135 10.5553 8.14851 11.0832C7.0399 11.3786 6.12682 12.2654 5.82273 13.3632C5.2793 15.3686 5.2793 19.5275 5.2793 19.5275C5.2793 19.5275 5.2793 23.7074 5.82273 25.6919C6.12714 26.7897 7.01816 27.6552 8.14867 27.9508C10.2353 28.4997 18.4738 28.4997 18.4738 28.4997C18.4738 28.4997 26.7337 28.4997 28.7987 27.9718C29.9292 27.6763 30.8204 26.8108 31.1248 25.713C31.6681 23.7074 31.6681 19.5486 31.6681 19.5486C31.6681 19.5486 31.6898 15.3686 31.1245 13.3632ZM15.8436 23.3697V15.6853L22.7124 19.5275L15.8436 23.3697Z"
                        fill="currentColor"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copy-rights">
          <p>
            © <Trans>2024 Laxce. All Rights Reserved</Trans>
          </p>
        </div>
      </div>
    </div>
  );
}
