import { Trans } from '@lingui/macro'
import styled from 'styled-components/macro'
import { Percent } from '@uniswap/sdk-core'

import { RowBetween, RowFixed } from '../Row'
import { TYPE } from '../../theme'
import SettingTab from "../../components/Settings/settingTab"

const StyledSwapHeader = styled.div`
  padding: 1rem 0;
  width: 100%;
  color: ${({ theme }) => theme.text2};
`

export default function SwapHeader({ allowedSlippage }: { allowedSlippage: Percent }) {
  return (
          <SettingTab placeholderSlippage={allowedSlippage} />
  )
}
